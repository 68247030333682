import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Link} from "gatsby";
import TempImage from "../images/pop_placeholder.jpg";
import rawPreviewImage from "../images/raw-preview-1.jpg";
import tinyrainbootPreviewImage from "../images/preview-tinyrainboot.jpg";
import cyborgCulturImage from "../images/cyborg-cultur-preview.png";
const IndexPage = () => (

  <Layout>
    <Seo title="Collections" />

  <section className='section-title white'>
      <div className='container clearfix'>
          <h1>Collections</h1>
      </div>
  </section>

    <div className='container clearfix collections-list'>
    <div className='col30 left collection-item'>
            <Link to="/cyborg-culture/" className='image'>
                <img src={cyborgCulturImage} alt="RAW"/>
                <a className='button black right remaining'>53 minted</a>
                <a className='button black right soldout'>closed</a>
            </Link>
            <div className='details'>
                <h4>Cyborg culture <br></br><span>by Ira Greenberg</span></h4>
                <Link className='button black detailed-view' to='/cyborg-culture/'>View</Link>
            </div>
        </div>

    <div className='col30 left collection-item'>
            <Link to="/tinyrainboot/" className='image'>
                <img src={tinyrainbootPreviewImage} alt="The Contours of a Dream"/>
                <a className='button black right remaining'>118 minted</a>
                <a className='button black right soldout'>closed</a>
            </Link>
            <div className='details'>
                <h4>The Contours of a Dream <br></br><span>by Tinyrainboot</span></h4>
                <Link className='button black detailed-view' to='/tinyrainboot/'>View</Link>
            </div>
        </div>

        <div className='col30 left collection-item'>
            <Link to="/raw/" className='image'>
                <img src={rawPreviewImage} alt="RAW"/>
                <a className='button black right remaining'>73 minted</a>
                <a className='button black right soldout'>closed</a>
            </Link>
            <div className='details'>
                <h4>RAW <br></br><span>by Stephan Vasement</span></h4>
                <Link className='button black detailed-view' to='/raw/'>View</Link>
            </div>
        </div>

        <div className='col30 left collection-item'>
            <Link to="/populus/" className='image'>
                <img src={TempImage}  alt="Populus" />
                <a className='button black right remaining'>150 minted</a>
                <a className='button black right soldout'>sold out</a>
            </Link>
            <div className='details'>
                <h4>Populus <span>by _DreamCult_</span></h4>
                <Link className='button black detailed-view' to='/populus/'>View</Link>
            </div>
        </div>

        <div className='col30 left collection-item'>
            <Link to="/waves-gallery/" className='image'>
                <video autoPlay="autoplay" muted={true} loop={true}
                poster="https://d28a5q050a9bu1.cloudfront.net/images/waves/real-waves/999+.png">
                <source src="https://d28a5q050a9bu1.cloudfront.net/videos/Wave-999-Video.mp4#t=9"
                type="video/mp4"/>
                </video>
                <a className='button black right remaining'>1000 minted</a>
                <a className='button black right soldout'>sold out</a>
            </Link>
            <div className='details'>
                <h4>Waves <span>by Mccannatron</span></h4>
                <Link className='button black detailed-view' to='/waves-gallery/'>View</Link>
            </div>
        </div>


        <div className='col30 left collection-item'>
            <Link to="/epochs-gallery/" className='image'>
                <video autoPlay="autoplay" muted={true} loop={true}
                poster="https://d28a5q050a9bu1.cloudfront.net/images/epochs/0.png"><source src="https://d28a5q050a9bu1.cloudfront.net/videos/Epoch-Animation-home.mp4" type="video/mp4"/></video>
                <a className='button black right remaining'>1000 minted</a>
                <a className='button black right soldout'>sold out</a>
            </Link>
            <div className='details'>
                <h4>Epochs <span>by Eko33</span></h4>
                <Link className='button black detailed-view' to='/epochs-gallery/'>View</Link>
            </div>

        </div>
    </div>
  </Layout>
)

export default IndexPage
